import _ from 'lodash';
import {
  CREATE_CURRENCY_EXCHANGE_SUCCESS,
  DELETE_CURRENCY_EXCHANGE_SUCCESS,
  UPDATE_CURRENCY_EXCHANGE_SUCCESS
} from "state/model/currencyExchanges/currencyExchange/constants";
import {
  INIT_CURRENCY_EXCHANGES,
  LIST_CURRENCY_EXCHANGES,
  LIST_CURRENCY_EXCHANGES_FAILED,
  LIST_CURRENCY_EXCHANGES_SUCCESS
} from "state/model/currencyExchanges/currencyExchanges/constants";

const INIT_STATE = {
  currencyExchanges: {},
  loading: true,
  error: null,
};

const currencyExchangesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CURRENCY_EXCHANGES:
      return INIT_STATE;

    case LIST_CURRENCY_EXCHANGES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CURRENCY_EXCHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currencyExchanges: _.mapKeys(action.payload.currencyExchanges, 'id'),
      };

    case LIST_CURRENCY_EXCHANGES_FAILED:
      return {
        ...state,
        currencyExchanges: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CURRENCY_EXCHANGE_SUCCESS:
      return {
        ...state,
        currencyExchanges: {
          [action.payload.currencyExchange.id]: action.payload.currencyExchange,
          ...state.currencyExchanges,
        },
      };

    case UPDATE_CURRENCY_EXCHANGE_SUCCESS:
      return {
        ...state,
        currencyExchanges: {
          ...state.currencyExchanges,
          [action.payload.currencyExchange.id]: action.payload.currencyExchange,
        },
      };

    case DELETE_CURRENCY_EXCHANGE_SUCCESS:
      return {
        ...state,
        currencyExchanges: _.omit(state.currencyExchanges, action.payload.currencyExchange.id)
      };

    default:
      return { ...state };
  }
};

export default currencyExchangesReducers;
