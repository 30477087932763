import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_CURRENCY_EXCHANGE,
  DELETE_CURRENCY_EXCHANGE,
  FETCH_CURRENCY_EXCHANGE,
  UPDATE_CURRENCY_EXCHANGE
} from "state/model/currencyExchanges/currencyExchange/constants";
import {
  apiCreateCurrencyExchange,
  apiDeleteCurrencyExchange,
  apiFetchCurrencyExchange,
  apiUpdateCurrencyExchange
} from "api/model/currencyExchange/apiCurrencyExchange";
import {
  createCurrencyExchangeFailed,
  createCurrencyExchangeSuccess,
  deleteCurrencyExchangeFailed,
  deleteCurrencyExchangeSuccess,
  fetchCurrencyExchangeFailed,
  fetchCurrencyExchangeSuccess,
  initCurrencyExchange,
  updateCurrencyExchangeFailed,
  updateCurrencyExchangeSuccess
} from "state/model/currencyExchanges/currencyExchange/currencyExchangeActions";
import { toastDeleted, toastError, toastSaved } from "state/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "state/ui/modal/modalActions";
import { INDEX_CURRENCY_EXCHANGES_PATH } from "routes/currencyExchange/constants";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { generateRoute } from "helpers/routesUtils";
import history from "routes/history";


function* currencyExchangeSaga() {
  yield all([
    fork(watchFetchCurrencyExchange),
    fork(watchUpdateCurrencyExchange),
    fork(watchCreateCurrencyExchange),
    fork(watchDeleteCurrencyExchange),
  ]);
}

// Fetch CurrencyExchange
export function* watchFetchCurrencyExchange() {
  yield takeEvery(FETCH_CURRENCY_EXCHANGE, sagaFetchCurrencyExchange);
}

export function* sagaFetchCurrencyExchange({ payload }) {
  try {
    const response = yield call(apiFetchCurrencyExchange, payload);

    const { currencyExchange } = response.data;
    yield put(fetchCurrencyExchangeSuccess({ currencyExchange }));

  } catch (e) {
    yield put(fetchCurrencyExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create CurrencyExchange
export function* watchCreateCurrencyExchange() {
  yield takeLeading(CREATE_CURRENCY_EXCHANGE, sagaCreateCurrencyExchange);
}

export function* sagaCreateCurrencyExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateCurrencyExchange, payload);

    const { currencyExchange } = response.data;
    yield put(createCurrencyExchangeSuccess({ currencyExchange }));
    yield put(toastSaved({ name: `CurrencyExchange` }));


    history.push(generateRoute(INDEX_CURRENCY_EXCHANGES_PATH));
    yield put(initCurrencyExchange());


  } catch (e) {
    yield put(createCurrencyExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update CurrencyExchange
export function* watchUpdateCurrencyExchange() {
  yield takeLeading(UPDATE_CURRENCY_EXCHANGE, sagaUpdateCurrencyExchange);
}

export function* sagaUpdateCurrencyExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateCurrencyExchange, payload);

    const { currencyExchange } = response.data;
    yield put(updateCurrencyExchangeSuccess({ currencyExchange }));
    yield put(toastSaved({ name: 'CurrencyExchange' }));
    history.push(generateRoute(INDEX_CURRENCY_EXCHANGES_PATH));
    yield put(initCurrencyExchange());

  } catch (e) {
    yield put(updateCurrencyExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete CurrencyExchange
export function* watchDeleteCurrencyExchange() {
  yield takeLeading(DELETE_CURRENCY_EXCHANGE, sagaDeleteCurrencyExchange);
}

export function* sagaDeleteCurrencyExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteCurrencyExchange, payload);

    const { currencyExchange } = response.data;
    yield put(deleteCurrencyExchangeSuccess({ currencyExchange }));
    yield put(toastDeleted({ name: 'CurrencyExchange' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteCurrencyExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default currencyExchangeSaga;
