import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_USER,
  CLEAR_USER,
  FETCH_CURRENT_STAFF,
  FETCH_CURRENT_STAFF_FAILED,
  FETCH_CURRENT_STAFF_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD_SUCCESS,
  LOG_PATH,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REFRESH_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SEND_EMAIL_CONFIRMATION,
  SEND_EMAIL_CONFIRMATION_FAILED,
  SEND_EMAIL_CONFIRMATION_SUCCESS,
  SETUP_ACCOUNT,
  SETUP_ACCOUNT_FAILED,
  SETUP_ACCOUNT_SUCCESS,
} from 'state/auth/authConstants';

import { initIsLoggedIn, initJwtToken } from "helpers/authUtils";

const INIT_STATE = {
  isLoggedIn: initIsLoggedIn(),
  currentUser: {},
  token: initJwtToken(),
  loading: false,
  logPath: '/',
  error: null,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      const { token: loginToken, currentAuth: loginUser } = action.payload;
      return { ...state, token: loginToken, currentUser: loginUser, loading: false, error: null, isLoggedIn: true };

    case LOGIN_USER_FAILED:
      return {
        ...state,
        token: null,
        currentUser: null,
        error: action.payload.error,
        loading: false,
        isLoggedIn: false
      };

    case SEND_EMAIL_CONFIRMATION_FAILED:
      return {
        ...state,
        token: null,
        currentUser: null,
        error: action.payload.error,
        loading: false,
        isLoggedIn: false
      };

    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        token: null,
        isLoggedIn: false,
        error: null,
        loading: false
      };

    case CLEAR_USER:
      return {
        ...state,
        currentUser: null,
        token: null,
        isLoggedIn: false,
        error: null,
        loading: false
      };

    case LOG_PATH:
      const { path: logPath } = action.payload;
      return { ...state, logPath: logPath };

    case CHECK_USER:
      return { ...state };

    case REFRESH_USER:
      return {
        ...state,
        currentUser: action.payload.current.currentAuth,
        isLoggedIn: true
      };

    case FORGET_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload.passwordResetStatus,
        loading: false,
        error: null
      };

    case FORGET_PASSWORD_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SETUP_ACCOUNT:
      return {
        ...state,
        loading: true,
      };

    case SETUP_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case SETUP_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_CURRENT_STAFF:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CURRENT_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case FETCH_CURRENT_STAFF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default authReducer;
