import { combineReducers } from 'redux';
import formLineAttachmentReducers from "state/ui/form/lineAttachment/formLineAttachmentReducers";
import formReceivedNoticeReducers from "state/ui/form/receivedNotice/formReceivedNoticeReducers";
import formTransactionReducers from "state/ui/form/transaction/formTransactionReducers";
import formCurrencyExchangeReducers from "state/ui/form/currencyExchange/formCurrencyExchangeReducers";

export default combineReducers({
  lineAttachment: formLineAttachmentReducers,
  receivedNotice: formReceivedNoticeReducers,
  transaction: formTransactionReducers,
  currencyExchange: formCurrencyExchangeReducers,
});
