import {
  ERROR_FORM_TRANSACTION,
  LOADING_FORM_TRANSACTION,
  RESET_FORM_TRANSACTION
} from "state/ui/form/transaction/constants";
import {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILED,
  CREATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION, UPDATE_TRANSACTION_FAILED, UPDATE_TRANSACTION_SUCCESS
} from "state/model/transactions/transaction/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formTransactionReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_TRANSACTION:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_TRANSACTION:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formTransactionReducers;
