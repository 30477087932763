// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';

// Themes
import 'assets/scss/Saas.scss';
import ConfigTheme from "configs/components/ConfigTheme";
import ConfigToast from "configs/components/ConfigToast";

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {

  componentDidMount(): * {
    const environment = window._env_.ENVIRONMENT;
    document.title = `${ environment !== 'Production' ? `${ environment } - ` : '' }FeiYuan Logistic`;
  }

  render() {
    return (
      <React.Fragment>
        <ConfigTheme />
        <ConfigToast />

        <Routes />
      </React.Fragment>
    );
  }
}

export default App;
