import { all } from 'redux-saga/effects';
import lineAttachmentSaga from "state/model/lineAttachments/lineAttachment/lineAttachmentSaga";
import lineAttachmentsSaga from "state/model/lineAttachments/lineAttachments/lineAttachmentsSaga";
import receivedNoticeSaga from "state/model/receivedNotices/receivedNotice/receivedNoticeSaga";
import receivedNoticesSaga from "state/model/receivedNotices/receivedNotices/receivedNoticesSaga";
import transactionSaga from "state/model/transactions/transaction/transactionSaga";
import transactionsSaga from "state/model/transactions/transactions/transactionsSaga";
import currencyExchangeSaga from "state/model/currencyExchanges/currencyExchange/currencyExchangeSaga";
import currencyExchangesSaga from "state/model/currencyExchanges/currencyExchanges/currencyExchangesSaga";


function* modelSaga() {
  yield all([
    lineAttachmentSaga(),
    lineAttachmentsSaga(),
    receivedNoticeSaga(),
    receivedNoticesSaga(),
    transactionSaga(),
    transactionsSaga(),
    currencyExchangeSaga(),
    currencyExchangesSaga(),
  ]);
}

export default modelSaga;
