import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoutes } from "routes/auth/routes";
import { hiddenReceivedNoticeRoutes } from "routes/receivedNotice/routes";
import PrivateRoute from "routes/PrivateRoute";
import { hiddenTransactionRoutes } from "routes/transaction/routes";
import { NEW_TRANSACTION_PATH } from "routes/transaction/constants";

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={ NEW_TRANSACTION_PATH }/>,
  route: PrivateRoute,
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const hiddenRoutes = {
  children: [
    ...hiddenReceivedNoticeRoutes.children,
    ...hiddenTransactionRoutes.children,
  ],
};

// All routes
const allRoutes = [
  rootRoute,
  authRoutes,
  hiddenRoutes,
];

const authProtectedRoutes = [
  rootRoute,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
