import {
  INIT_CURRENCY_EXCHANGES,
  LIST_CURRENCY_EXCHANGES,
  LIST_CURRENCY_EXCHANGES_FAILED,
  LIST_CURRENCY_EXCHANGES_SUCCESS
} from "state/model/currencyExchanges/currencyExchanges/constants";

export const initCurrencyExchanges = () => ({
  type: INIT_CURRENCY_EXCHANGES,
  payload: {}
});

export const listCurrencyExchanges = () => ({
  type: LIST_CURRENCY_EXCHANGES,
  payload: {}
});

export const listCurrencyExchangesSuccess = ({ currencyExchanges }) => ({
  type: LIST_CURRENCY_EXCHANGES_SUCCESS,
  payload: { currencyExchanges }
});

export const listCurrencyExchangesFailed = ({ error }) => ({
  type: LIST_CURRENCY_EXCHANGES_FAILED,
  payload: { error }
});
