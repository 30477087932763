import {
  CREATE_TRANSACTION_API,
  DELETE_TRANSACTION_API,
  FETCH_NEW_TRANSACTION_API,
  FETCH_TRANSACTION_API,
  INDEX_TRANSACTIONS_API,
  UPDATE_TRANSACTION_API,
} from "api/model/transaction/constants";
import api from "api/api";
import _ from 'lodash';
import { extractRailsClassNameFrom } from "helpers/railsUtils";
import { generateRoute } from "helpers/routesUtils";
import { extractIdFrom } from "helpers/stringUtils";

export const apiListTransactions = () => {
  const { url, method } = INDEX_TRANSACTIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateTransaction = ({ transaction }) => {
  const { url, method } = CREATE_TRANSACTION_API;

  return api({
    method,
    url,
    data: transactionParams({ transaction }),
  });
};

export const apiFetchTransaction = ({ transactionId }) => {
  const { url, method } = FETCH_TRANSACTION_API;

  return api({
    method,
    url: generateRoute(url, transactionId),
  });
};

export const apiFetchNewTransaction = () => {
  const { url, method } = FETCH_NEW_TRANSACTION_API;

  return api({
    method,
    url,
  });
};

export const apiUpdateTransaction = ({ transaction }) => {
  const { url, method } = UPDATE_TRANSACTION_API;

  return api({
    method,
    url: generateRoute(url, transaction.id),
    data: transactionParams({ transaction }),
  });
};

export const apiDeleteTransaction = ({ transaction }) => {
  const { url, method } = DELETE_TRANSACTION_API;

  return api({
    method,
    url: generateRoute(url, transaction.id),
  });
};

const transactionParams = ({ transaction }) => {
  const {
    type,
    changedAmount,
    note,
    currencyExchange,
    lineAttachments,
  } = transaction;

  const lineAttachmentsAttributes = _.map(lineAttachments, (lineAttachment) => {
    return { id: extractIdFrom(lineAttachment.id) };
  });

  return {
    transactionBase: {
      type: extractRailsClassNameFrom(type),
      changedAmount,
      note,
      currencyExchangeRate: currencyExchange ? currencyExchange.rate : 0,
      currencyExchangeId: currencyExchange ? extractIdFrom(currencyExchange.id) : null,
      lineAttachmentsAttributes,
    },
  };
};
