import { combineReducers } from 'redux';
import lineAttachmentReducers from "state/model/lineAttachments/lineAttachment/lineAttachmentReducers";
import lineAttachmentsReducers from "state/model/lineAttachments/lineAttachments/lineAttachmentsReducers";
import receivedNoticeReducers from "state/model/receivedNotices/receivedNotice/receivedNoticeReducers";
import receivedNoticesReducers from "state/model/receivedNotices/receivedNotices/receivedNoticesReducers";
import transactionReducers from "state/model/transactions/transaction/transactionReducers";
import transactionsReducers from "state/model/transactions/transactions/transactionsReducers";
import currencyExchangeReducers from "state/model/currencyExchanges/currencyExchange/currencyExchangeReducers";
import currencyExchangesReducers from "state/model/currencyExchanges/currencyExchanges/currencyExchangesReducers";


export default combineReducers({
  lineAttachment: lineAttachmentReducers,
  lineAttachments: lineAttachmentsReducers,
  receivedNotice: receivedNoticeReducers,
  receivedNotices: receivedNoticesReducers,
  transaction: transactionReducers,
  transactions: transactionsReducers,
  currencyExchange: currencyExchangeReducers,
  currencyExchanges: currencyExchangesReducers,
});
