import React from "react";
import { BASE_TRANSACTION_PATH, INDEX_TRANSACTIONS_PATH, NEW_TRANSACTION_PATH, SHOW_TRANSACTION_PATH } from "routes/transaction/constants";
import PrivateRoute from "routes/PrivateRoute";

const Transactions = React.lazy(() => import('pages/transactions/index/Transactions'));
const NewTransaction = React.lazy(() => import('pages/transactions/new/NewTransaction'));
const ShowTransaction = React.lazy(() => import('pages/transactions/show/ShowTransaction'));

export const transactionRoutes = {
  path: INDEX_TRANSACTIONS_PATH,
  name: '预付款审批',
  icon: 'far fa-star',
  exact: true,
  component: Transactions,
  route: PrivateRoute,
  // children: [
  // {
  //   path: INDEX_TRANSACTIONS_PATH,
  //   exact: true,
  //   name: 'Transactions',
  //   component: Transactions,
  //   route: PrivateRoute,
  // },
  // {
  //   path: NEW_TRANSACTION_PATH,
  //   exact: true,
  //   name: 'Add New',
  //   component: NewTransaction,
  //   route: PrivateRoute,
  // },
  // ],
};

export const hiddenTransactionRoutes = {
  children: [
    {
      path: NEW_TRANSACTION_PATH,
      exact: true,
      name: 'Add New',
      component: NewTransaction,
      route: PrivateRoute,
    },

    // {
    //   path: SHOW_TRANSACTION_PATH,
    //   exact: true,
    //   name: 'Show Transaction',
    //   component: ShowTransaction,
    //   route: PrivateRoute,
    // }
  ]
}
