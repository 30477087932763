import {
  CREATE_CURRENCY_EXCHANGE,
  CREATE_CURRENCY_EXCHANGE_FAILED,
  CREATE_CURRENCY_EXCHANGE_SUCCESS,
  DELETE_CURRENCY_EXCHANGE, DELETE_CURRENCY_EXCHANGE_FAILED, DELETE_CURRENCY_EXCHANGE_SUCCESS,
  FETCH_CURRENCY_EXCHANGE,
  FETCH_CURRENCY_EXCHANGE_FAILED,
  FETCH_CURRENCY_EXCHANGE_SUCCESS,
  INIT_CURRENCY_EXCHANGE,
  UPDATE_CURRENCY_EXCHANGE,
  UPDATE_CURRENCY_EXCHANGE_FAILED,
  UPDATE_CURRENCY_EXCHANGE_SUCCESS,
  ASSIGN_CURRENCY_EXCHANGE
} from "state/model/currencyExchanges/currencyExchange/constants";

export const initCurrencyExchange = () => ({
  type: INIT_CURRENCY_EXCHANGE,
  payload: {}
});

export const createCurrencyExchange = ({ currencyExchange }) => ({
  type: CREATE_CURRENCY_EXCHANGE,
  payload: { currencyExchange }
});

export const createCurrencyExchangeSuccess = ({ currencyExchange }) => ({
  type: CREATE_CURRENCY_EXCHANGE_SUCCESS,
  payload: { currencyExchange }
});

export const createCurrencyExchangeFailed = ({ error }) => ({
  type: CREATE_CURRENCY_EXCHANGE_FAILED,
  payload: { error }
});

export const fetchCurrencyExchange = ({ currencyExchangeId }) => ({
  type: FETCH_CURRENCY_EXCHANGE,
  payload: { currencyExchangeId }
});

export const fetchCurrencyExchangeSuccess = ({ currencyExchange }) => ({
  type: FETCH_CURRENCY_EXCHANGE_SUCCESS,
  payload: { currencyExchange }
});

export const fetchCurrencyExchangeFailed = ({ error }) => ({
  type: FETCH_CURRENCY_EXCHANGE_FAILED,
  payload: { error }
});

export const updateCurrencyExchange = ({ currencyExchange }) => ({
  type: UPDATE_CURRENCY_EXCHANGE,
  payload: { currencyExchange }
});

export const updateCurrencyExchangeSuccess = ({ currencyExchange }) => ({
  type: UPDATE_CURRENCY_EXCHANGE_SUCCESS,
  payload: { currencyExchange }
});

export const updateCurrencyExchangeFailed = ({ error }) => ({
  type: UPDATE_CURRENCY_EXCHANGE_FAILED,
  payload: { error }
});

export const deleteCurrencyExchange = ({ currencyExchange }) => ({
  type: DELETE_CURRENCY_EXCHANGE,
  payload: { currencyExchange }
});

export const deleteCurrencyExchangeSuccess = ({ currencyExchange }) => ({
  type: DELETE_CURRENCY_EXCHANGE_SUCCESS,
  payload: { currencyExchange }
});

export const deleteCurrencyExchangeFailed = ({ error }) => ({
  type: DELETE_CURRENCY_EXCHANGE_FAILED,
  payload: { error }
});

export const assignCurrencyExchange = ({ fields }) => ({
  type: ASSIGN_CURRENCY_EXCHANGE,
  payload: { fields }
});
