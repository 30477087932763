export const SAGA_WAITING_TIME = 750;
export const SAGA_WAITING_TIME_HALF = 400;
export const SAGA_WAITING_TIME_300 = 300;
export const SAGA_WAITING_TIME_2000 = 2000;

export const SIZE_PER_PAGE_LIST = [
  { text: '10', value: 10, },
  { text: '20', value: 20, },
  { text: '30', value: 30, },
];
