import {
  ASSIGN_TRANSACTION_FIELDS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILED,
  CREATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_FAILED,
  DELETE_TRANSACTION_SUCCESS,
  FETCH_NEW_TRANSACTION,
  FETCH_TRANSACTION,
  FETCH_TRANSACTION_FAILED,
  FETCH_TRANSACTION_SUCCESS,
  INIT_TRANSACTION,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_FAILED,
  UPDATE_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_SUCCESS
} from "state/model/transactions/transaction/constants";

export const initTransaction = () => ({
  type: INIT_TRANSACTION,
  payload: {}
});

export const createTransaction = ({ transaction }) => ({
  type: CREATE_TRANSACTION,
  payload: { transaction }
});

export const createTransactionSuccess = ({ transaction }) => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload: { transaction }
});

export const createTransactionFailed = ({ error }) => ({
  type: CREATE_TRANSACTION_FAILED,
  payload: { error }
});

export const fetchTransaction = ({ transactionId }) => ({
  type: FETCH_TRANSACTION,
  payload: { transactionId }
});

export const fetchTransactionSuccess = ({ transaction }) => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: { transaction }
});

export const fetchTransactionFailed = ({ error }) => ({
  type: FETCH_TRANSACTION_FAILED,
  payload: { error }
});

export const updateTransaction = ({ transaction }) => ({
  type: UPDATE_TRANSACTION,
  payload: { transaction }
});

export const updateTransactionSuccess = ({ transaction }) => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload: { transaction }
});

export const updateTransactionFailed = ({ error }) => ({
  type: UPDATE_TRANSACTION_FAILED,
  payload: { error }
});

export const deleteTransaction = ({ transaction }) => ({
  type: DELETE_TRANSACTION,
  payload: { transaction }
});

export const deleteTransactionSuccess = ({ transaction }) => ({
  type: DELETE_TRANSACTION_SUCCESS,
  payload: { transaction }
});

export const deleteTransactionFailed = ({ error }) => ({
  type: DELETE_TRANSACTION_FAILED,
  payload: { error }
});

export const updateTransactionStatus = ({ transaction, operation }) => ({
  type: UPDATE_TRANSACTION_STATUS,
  payload: { transaction, operation }
});

export const fetchNewTransaction = () => ({
  type: FETCH_NEW_TRANSACTION,
  payload: {}
});

export const assignTransactionFields = ({ ...fields }) => ({
  type: ASSIGN_TRANSACTION_FIELDS,
  payload: { ...fields }
});