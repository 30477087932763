/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';
export const LOG_PATH = 'LOG_PATH';
export const CHECK_USER = 'CHECK_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const CHANGE_THEME = 'CHANGE_THEME';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const SETUP_ACCOUNT = 'SETUP_ACCOUNT';
export const SETUP_ACCOUNT_SUCCESS = 'SETUP_ACCOUNT_SUCCESS';
export const SETUP_ACCOUNT_FAILED = 'SETUP_ACCOUNT_FAILED';

export const FETCH_CURRENT_STAFF = 'FETCH_CURRENT_STAFF';
export const FETCH_CURRENT_STAFF_SUCCESS = 'FETCH_CURRENT_STAFF_SUCCESS';
export const FETCH_CURRENT_STAFF_FAILED = 'FETCH_CURRENT_STAFF_FAILED';

// export const UPDATE_SUB_DOMAIN = 'UPDATE_SUB_DOMAIN';

export const SEND_EMAIL_CONFIRMATION = 'SEND_EMAIL_CONFIRMATION';
export const SEND_EMAIL_CONFIRMATION_SUCCESS = 'SEND_EMAIL_CONFIRMATION_SUCCESS';
export const SEND_EMAIL_CONFIRMATION_FAILED = 'SEND_EMAIL_CONFIRMATION_FAILED';