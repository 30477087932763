export const INIT_CURRENCY_EXCHANGE = 'INIT_CURRENCY_EXCHANGE';

export const FETCH_CURRENCY_EXCHANGE = 'FETCH_CURRENCY_EXCHANGE';
export const FETCH_CURRENCY_EXCHANGE_SUCCESS = 'FETCH_CURRENCY_EXCHANGE_SUCCESS';
export const FETCH_CURRENCY_EXCHANGE_FAILED = 'FETCH_CURRENCY_EXCHANGE_FAILED';

export const CREATE_CURRENCY_EXCHANGE = 'CREATE_CURRENCY_EXCHANGE';
export const CREATE_CURRENCY_EXCHANGE_SUCCESS = 'CREATE_CURRENCY_EXCHANGE_SUCCESS';
export const CREATE_CURRENCY_EXCHANGE_FAILED = 'CREATE_CURRENCY_EXCHANGE_FAILED';

export const UPDATE_CURRENCY_EXCHANGE = 'UPDATE_CURRENCY_EXCHANGE';
export const UPDATE_CURRENCY_EXCHANGE_SUCCESS = 'UPDATE_CURRENCY_EXCHANGE_SUCCESS';
export const UPDATE_CURRENCY_EXCHANGE_FAILED = 'UPDATE_CURRENCY_EXCHANGE_FAILED';

export const DELETE_CURRENCY_EXCHANGE = 'DELETE_CURRENCY_EXCHANGE';
export const DELETE_CURRENCY_EXCHANGE_SUCCESS = 'DELETE_CURRENCY_EXCHANGE_SUCCESS';
export const DELETE_CURRENCY_EXCHANGE_FAILED = 'DELETE_CURRENCY_EXCHANGE_FAILED';

export const ASSIGN_CURRENCY_EXCHANGE = 'ASSIGN_CURRENCY_EXCHANGE';
