import {
  CREATE_CURRENCY_EXCHANGE_API,
  INDEX_CURRENCY_EXCHANGES_API,
  FETCH_CURRENCY_EXCHANGE_API,
  DELETE_CURRENCY_EXCHANGE_API,
  UPDATE_CURRENCY_EXCHANGE_API
} from "api/model/currencyExchange/constants";
import { generateRoute } from "helpers/routesUtils";
import api from "api/api";

export const apiListCurrencyExchanges = () => {
  const { url, method } = INDEX_CURRENCY_EXCHANGES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateCurrencyExchange = ({ currencyExchange }) => {
  const { url, method } = CREATE_CURRENCY_EXCHANGE_API;

  return api({
    method,
    url,
    data: currencyExchangeParams({ currencyExchange }),
  });
};

export const apiFetchCurrencyExchange = ({ currencyExchangeId }) => {
  const { url, method } = FETCH_CURRENCY_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, currencyExchangeId),
  });
};

export const apiUpdateCurrencyExchange = ({ currencyExchange }) => {
  const { url, method } = UPDATE_CURRENCY_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, currencyExchange.id),
    data: currencyExchangeParams({ currencyExchange }),
  });
};

export const apiDeleteCurrencyExchange = ({ currencyExchange }) => {
  const { url, method } = DELETE_CURRENCY_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, currencyExchange.id),
  });
};

const currencyExchangeParams = ({ currencyExchange }) => {
  const {} = currencyExchange;

  return {
    currencyExchange: {

    }
  }
};
