import { extractClassNameFrom } from "helpers/stringUtils";

export const extractRailsClassNameFrom = (string) => {
  const reduxName = extractClassNameFrom(string);
  return railsClassNameMapper[reduxName];
};

export const railsClassNameMapper = {
  noticeChannel: 'Notification::Message::NoticeChannel',
  pack: 'Pack',
  orderPack: 'Order::Base',
  transAlipay: 'Transaction::Alipay',
  transBankAud: 'Transaction::BankAud',
  transBankCny: 'Transaction::BankCny',
};
