import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_CURRENCY_EXCHANGES } from "state/model/currencyExchanges/currencyExchanges/constants";
import { apiListCurrencyExchanges } from "api/model/currencyExchange/apiCurrencyExchange";
import { listCurrencyExchangesFailed, listCurrencyExchangesSuccess } from "state/model/currencyExchanges/currencyExchanges/currencyExchangesActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* currencyExchangesSaga() {
  yield all([
    fork(watchListCurrencyExchanges),
  ]);
}

// List CurrencyExchanges
export function* watchListCurrencyExchanges() {
  yield takeEvery(LIST_CURRENCY_EXCHANGES, sagaListCurrencyExchanges);
}

export function* sagaListCurrencyExchanges() {
  try {
    const response = yield call(apiListCurrencyExchanges);

    const { currencyExchanges } = response.data;

    yield put(listCurrencyExchangesSuccess({ currencyExchanges }));

  } catch (e) {
    yield put(listCurrencyExchangesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default currencyExchangesSaga;
