import {
  INIT_TRANSACTIONS,
  LIST_TRANSACTIONS,
  LIST_TRANSACTIONS_FAILED,
  LIST_TRANSACTIONS_SUCCESS
} from "state/model/transactions/transactions/constants";

export const initTransactions = () => ({
  type: INIT_TRANSACTIONS,
  payload: {}
});

export const listTransactions = () => ({
  type: LIST_TRANSACTIONS,
  payload: {}
});

export const listTransactionsSuccess = ({ transactions }) => ({
  type: LIST_TRANSACTIONS_SUCCESS,
  payload: { transactions }
});

export const listTransactionsFailed = ({ error }) => ({
  type: LIST_TRANSACTIONS_FAILED,
  payload: { error }
});